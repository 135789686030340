import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';

const IndicesDetails = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        // Define the API URL you want to fetch data from
        const apiUrl =
            'https://eodhd.com/api/eod/1010.SR?fmt=json&&api_token=6523dd3ad3c6c2.14907660&period=d&from=2023-01-01&to=2023-10-09';

        // Make a GET request using Axios
        axios
            .get(apiUrl)
            .then((response) => {
                // Assuming the response data structure matches the data you provided
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const chartData = {
        labels: data.map((item) => item.date),
        datasets: [
            {
                label: 'Close Price',
                data: data.map((item) => item.close),
                fill: false,
                borderColor: 'blue',
            },
        ],
    };

    const chartOptions = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
                title: {
                    display: true,
                    text: 'Date',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Close Price',
                },
            },
        },
    };

    return (
        <>
            <div>
                <h1>Data from GET request:</h1>
                <ul>
                    {data.map((item, index) => (
                        <li key={index}>
                            <p>Date: {item.date}</p>
                            <p>Open: {item.open}</p>
                            <p>High: {item.high}</p>
                            <p>Low: {item.low}</p>
                            <p>Close: {item.close}</p>
                            <p>Adjusted Close: {item.adjusted_close}</p>
                            <p>Volume: {item.volume}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

export default IndicesDetails;
