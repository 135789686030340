import React from 'react';
import styles from './TabChartDetails.css'
// import  './TabChartDetails.css'

const TabChartDetails = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <ul className='nav nav-tabs chart-tabs' id="myTabs">
                        <li className="nav-item">
                            <a className='nav-link active' data-bs-toggle="tab" href="#tab1">Key Parameters</a>
                        </li>
                        <li className="nav-item">
                            <a className='nav-link ' data-bs-toggle="tab" href="#tab2">Market News</a>
                        </li>
                        <li className="nav-item">
                            <a className='nav-link ' data-bs-toggle="tab" href="#tab3">Key Constituents</a>
                        </li>
                    </ul>

                    <div className='tab-content content-chart'>
                        <div id="tab1" className="tab-pane fade show active">
                            <div className= "row px-16 py-16">
                                <div className="col-md-3 border-bottom-col">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Prev. Close</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Open</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Day Range</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Last 52 Week Range</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>No. of Trades</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Avg. Trade Size*</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Volume Traded</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3 border-bottom-col ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Value Traded</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3  ">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>31 Dec. Value</span>
                                        <span className={`${styles['text-chart-number']}`} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>YTD</span>
                                        <span className={`${styles['text-chart-number']}`} style={{color: "#049C6B"}} >11,298.16</span>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="px-16 py-16">
                                        <span className={`${styles['text-chart-tab']} d-block `}>Change</span>
                                        <span className={`${styles['text-chart-number']}`} style={{color: "#049C6B"}} >11,298.16</span>
                                    </div>
                                </div>



                            </div>
                        </div>
                        <div id="tab2" className="tab-pane fade">

                        </div>
                        <div id="tab3" className="tab-pane fade">


                        </div>
                    </div>
                </div>
            </div>
{/*<div className="row">*/}
{/*    <div className="col-lg-12">*/}
{/*        <ul className={`nav nav-tabs ${styles['chart-tabs']}`} id="myTabs">*/}
{/*            <li className="nav-item">*/}
{/*                <a className="nav-link active" data-bs-toggle="tab" href="#tab1">Key Parameters</a>*/}
{/*            </li>*/}
{/*            <li className="nav-item">*/}
{/*                <a className="nav-link" data-bs-toggle="tab" href="#tab2">Market News</a>*/}
{/*            </li>*/}
{/*            <li className="nav-item">*/}
{/*                <a className="nav-link" data-bs-toggle="tab" href="#tab3">Key Constituents</a>*/}
{/*            </li>*/}
{/*        </ul>*/}

{/*        <div className="tab-content content-chart">*/}
{/*            <div id="tab1" className="tab-pane fade show active">*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}


{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*            </div>*/}
{/*            <div id="tab2" className="tab-pane fade">*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*            </div>*/}
{/*            <div id="tab3" className="tab-pane fade">*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="row px-16 py-16">*/}
{/*                        <div className="col-md-3">*/}
{/*                            <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 ">*/}
{/*                            <span className="d-block text-chart-tab">Open</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 px-16">*/}
{/*                            <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 px-16">*/}
{/*                            <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                    <div className="row px-16 py-16">*/}
{/*                        <div className="col-md-3">*/}
{/*                            <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 ">*/}
{/*                            <span className="d-block text-chart-tab">Open</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 px-16">*/}
{/*                            <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                        <div className="col-md-3 px-16">*/}
{/*                            <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                            <span className="text-chart-number">11,298.16</span>*/}
{/*                        </div>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}
{/*                <div className="row px-16 py-16">*/}
{/*                    <div className="col-md-3">*/}
{/*                        <span className="d-block text-chart-tab">Prev. Close</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 ">*/}
{/*                        <span className="d-block text-chart-tab">Open</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                    <div className="col-md-3 px-16">*/}
{/*                        <span className="d-block text-chart-tab">Day Range</span>*/}
{/*                        <span className="text-chart-number">11,298.16</span>*/}
{/*                    </div>*/}
{/*                </div>*/}

{/*            </div>*/}
{/*        </div>*/}
{/*    </div>*/}
{/*</div>*/}
        </>
    );
};

export default TabChartDetails;