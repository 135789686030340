import React from "react";
import yesIcon from "../../../assets/images/yesicon.svg";
import styles from "./FatwaIndicatorStock.module.css";
const FatwaIndicator = (props) => {
  return (
    <>
      <section className={`${styles["level-return"]}`}>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className=" d-flex ">
                <div className={`${styles["level-counter"]} mr-16`}>
                  <span
                    className={` d-block ${styles["counter-number"]} mb-8 `}
                  >
                    {props.numberIndices}
                  </span>
                </div>
                <div className={`${styles["fatwa"]}`}>
                  <div className="d-flex mb-8">
                    <span className={`$ d-block {styles['fatwa-text']} mb-8 `}>
                      Fatwa Indicator:
                    </span>
                    <span>
                      <img className="mr-8 ml-8" src={yesIcon} />
                      <span className={`${styles["fatwa-yes"]}`}>Yes</span>
                    </span>
                  </div>

                  <div className="d-flex gap-4">
                    <span className="result-number">
                      <span>Volume:</span> {props.volume}
                    </span>
                    <span className="result-number">
                      <span className={`${styles["text-key"]}`}>
                        52 week range:
                      </span>{" "}
                      {props.weekRange}
                    </span>
                  </div>
                </div>
              </div>

              <div className="d-flex align-items-center ">
                <div className="mr-16">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M51.3333 21.333L58.1333 12.2663C58.4305 11.8702 58.6114 11.3991 58.6559 10.9058C58.7003 10.4126 58.6066 9.91672 58.3851 9.47377C58.1636 9.03082 57.8232 8.6583 57.4019 8.39794C56.9807 8.13758 56.4952 7.99967 56 7.99967H24C24 7.29243 23.719 6.61415 23.2189 6.11406C22.7188 5.61396 22.0406 5.33301 21.3333 5.33301C20.6261 5.33301 19.9478 5.61396 19.4477 6.11406C18.9476 6.61415 18.6666 7.29243 18.6666 7.99967V53.333H16C15.2927 53.333 14.6145 53.614 14.1144 54.1141C13.6143 54.6142 13.3333 55.2924 13.3333 55.9997C13.3333 56.7069 13.6143 57.3852 14.1144 57.8853C14.6145 58.3854 15.2927 58.6663 16 58.6663H26.6666C27.3739 58.6663 28.0522 58.3854 28.5523 57.8853C29.0524 57.3852 29.3333 56.7069 29.3333 55.9997C29.3333 55.2924 29.0524 54.6142 28.5523 54.1141C28.0522 53.614 27.3739 53.333 26.6666 53.333H24V34.6663H56C56.4952 34.6663 56.9807 34.5284 57.4019 34.2681C57.8232 34.0077 58.1636 33.6352 58.3851 33.1922C58.6066 32.7493 58.7003 32.2534 58.6559 31.7602C58.6114 31.267 58.4305 30.7959 58.1333 30.3997L51.3333 21.333Z"
                      fill="#049C6B"
                    />
                  </svg>
                </div>
                <div>
                  <span className={`${styles["last-time"]}`}>
                    {props.lastTime}
                  </span>
                  <div className="d-flex align-items-center mt-8">
                    <span className={`${styles["last-time-number"]} mr-24`}>
                      {props.lastTimeScore}
                    </span>
                    <span
                      className={`${styles["number-flags number-flags-green"]}`}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M27.6208 24.0547C27.3242 24.3517 26.9725 24.5 26.5662 24.5H5.56646C5.15995 24.5 4.80852 24.3517 4.5116 24.0547C4.21468 23.7575 4.06635 23.4061 4.06635 22.9998C4.06635 22.5936 4.21468 22.2422 4.5116 21.9452L15.0115 11.4453C15.3087 11.1484 15.6602 10.9997 16.0663 10.9997C16.4725 10.9997 16.8243 11.1484 17.121 11.4453L27.6208 21.9453C27.9174 22.2422 28.0663 22.5936 28.0663 22.9999C28.0663 23.4061 27.9174 23.7575 27.6208 24.0547Z"
                          fill="#049C6B"
                        />
                      </svg>
                      {props.indicesNumber}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FatwaIndicator;
