import React from "react";
import styles from "./StockIndicesTable.module.css";
import { Link } from "react-router-dom";

function StockIndicesTable() {
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center py-24 px-24 gap-3"
        style={{ backgroundColor: "rgba(245, 247, 249, 0.6)" }}
      >
        <div className="d-flex align-items-center gap-2">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_90_1846)">
              <path
                d="M14.125 1.25H13.375V0H12.125V1.25H3.875V0H2.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V14.125C0 15.1589 0.841125 16 1.875 16H14.125C15.1589 16 16 15.1589 16 14.125V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM14.75 14.125C14.75 14.4696 14.4696 14.75 14.125 14.75H1.875C1.53038 14.75 1.25 14.4696 1.25 14.125V5.875H14.75V14.125ZM14.75 4.625H1.25V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H2.625V3.75H3.875V2.5H12.125V3.75H13.375V2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V4.625Z"
                fill="#1D1A39"
              />
              <path
                d="M2.375 7.1875H3.625V8.4375H2.375V7.1875ZM4.875 7.1875H6.125V8.4375H4.875V7.1875ZM7.375 7.1875H8.625V8.4375H7.375V7.1875ZM9.875 7.1875H11.125V8.4375H9.875V7.1875ZM12.375 7.1875H13.625V8.4375H12.375V7.1875ZM2.375 9.6875H3.625V10.9375H2.375V9.6875ZM4.875 9.6875H6.125V10.9375H4.875V9.6875ZM7.375 9.6875H8.625V10.9375H7.375V9.6875ZM9.875 9.6875H11.125V10.9375H9.875V9.6875ZM2.375 12.1875H3.625V13.4375H2.375V12.1875ZM4.875 12.1875H6.125V13.4375H4.875V12.1875ZM7.375 12.1875H8.625V13.4375H7.375V12.1875ZM9.875 12.1875H11.125V13.4375H9.875V12.1875ZM12.375 9.6875H13.625V10.9375H12.375V9.6875Z"
                fill="#1D1A39"
              />
            </g>
            <defs>
              <clipPath id="clip0_90_1846">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="fs-14 fw-600 text-primary-color ">
            {" "}
            Wed, 23 Aug 2023
          </span>
        </div>
        <div className={`${styles["table-filter"]}`}>
          <ul className=" d-flex align-items-center m-0 p-0 gap-2">
            <li className="">1H</li>
            <li className="">3H</li>
            <li className="">5H</li>
            <li className="">1D</li>
            <li className="">1W</li>
            <li className="">1M</li>
          </ul>
        </div>
      </div>

      <div className="table-responsive">
        <table className={`${styles["tabs-table"]} table`}>
          <thead className={`${styles["table-head"]}`}>
            <tr className="bg-white">
              <th scope="col" className=" ">
                Index name
              </th>
              <th scope="col" className="">
                LTP
              </th>
              <th scope="col" className="">
                Chg
              </th>
              <th scope="col" className="">
                Chg%
              </th>
              <th scope="col" className="">
                Last Update
              </th>
            </tr>
          </thead>
          <tbody className={`${styles["table-body"]}`}>
            <tr className="bg-white">
              <td className="text-secondary-color-10 fs-18 fw-500">
                <Link to="/StockIndices" className="text-secondary-color-10">
                  SARCO2030{" "}
                </Link>
              </td>
              <td className=" text-secondary-color-10 fs-18 fw-500">
                19,458.35
              </td>
              <td className="">
                <div className="d-flex align-items-center justify-content-center w-full">
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.27148 10.25H15.0065"
                      stroke="#049C6B"
                      stroke-width="2.11111"
                    />
                    <path
                      d="M10.079 5.49951L10.079 15.2345"
                      stroke="#049C6B"
                      stroke-width="2.11111"
                    />
                  </svg>
                  <span className={`${styles["chg-text-inc"]}`}>0,98</span>
                </div>
              </td>
              <td className="">
                <div className="d-flex align-items-center justify-content-center w-full gap-1 ">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66795 1.24807C7.06377 0.654342 7.93623 0.654342 8.33205 1.24808L14.4635 10.4453C14.9066 11.1099 14.4302 12 13.6315 12H1.36852C0.569822 12 0.0934302 11.1099 0.536467 10.4453L6.66795 1.24807Z"
                      fill="#049C6B"
                    />
                  </svg>
                  <span className={`${styles["chg-text-inc"]}`}>0,98</span>
                </div>
              </td>
              <td className="text-nowrap text-secondary-color-10 fs-18 fw-500">
                12:48:59 PM
              </td>
            </tr>
            <tr className="bg-white">
              <td className=" text-secondary-color-10 fs-18 fw-500">
                {" "}
                <Link to="/StockIndices1" className="text-secondary-color-10">
                  {" "}
                  SARCO2030{" "}
                </Link>
              </td>
              <td className="text-secondary-color-10 fs-18 fw-500 ">
                19,458.35
              </td>
              <td className="">
                <div className="d-flex align-items-center justify-content-center w-full">
                  <svg
                    width="19"
                    height="20"
                    viewBox="0 0 19 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.27777 10H12.6667"
                      stroke="#FD2523"
                      stroke-width="2.11111"
                    />
                  </svg>
                  <span className={`${styles["chg-text-dec"]}`}>0,98</span>
                </div>
              </td>
              <td className="">
                <div className="d-flex align-items-center justify-content-center w-full gap-1 ">
                  <svg
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.66795 10.7519C7.06377 11.3457 7.93623 11.3457 8.33205 10.7519L14.4635 1.5547C14.9066 0.890144 14.4302 0 13.6315 0H1.36852C0.569822 0 0.0934302 0.890145 0.536467 1.5547L6.66795 10.7519Z"
                      fill="#FD2523"
                    />
                  </svg>
                  <span className={`${styles["chg-text-dec"]}`}>0,98</span>
                </div>
              </td>
              <td className="text-nowrap text-secondary-color-10 fs-18 fw-500">
                12:48:59 PM
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}

export default StockIndicesTable;
