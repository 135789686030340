import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import styles from "../Layout/layout.module.css";

const Layout = () => {
  return (
    <>
      {/*<div className="container-fluid">*/}
      {/*    <div className="row">*/}
      {/*        <div className="col-md-3 ">*/}
      {/*            <Sidebar/>*/}
      {/*        </div>*/}
      {/*<div className="col-md-9">*/}
      {/*    <Header/>*/}
      {/*     <Outlet />*/}
      {/*</div>*/}
      {/*    </div>*/}
      {/*</div>*/}
      <div className="container-fluid ">
        <div className="d-flex justify-content-between">
          <div className={`${styles["sidebar-width"]}`}>
            <Sidebar />
          </div>
          <div className={`${styles["content-width"]}`}>
            <Header />
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
