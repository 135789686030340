import React from "react";

const BannerIndeices = (props) => {
  return (
    <>
      <div className={`px-4 py-5 d-flex align-items-center strip`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <mask
            id="mask0_568_4192"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="25"
            height="40"
          >
            <path
              d="M-7.62939e-05 0L-7.62939e-05 40H24.4764L24.4764 0H-7.62939e-05Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_568_4192)">
            <path
              d="M7.79514 26.4609L-7.62939e-05 19.9684L7.79514 13.4759L7.79514 26.4609Z"
              fill="white"
            />
            <path
              d="M24.4764 26.4609L16.6812 19.9684L24.4764 13.4759V26.4609Z"
              fill="white"
            />
            <path
              d="M24.4764 13.0625L16.6812 6.56997L24.4764 0.0774431V13.0625Z"
              fill="white"
            />
            <path
              d="M24.4764 39.8594L16.6812 33.3668L24.4764 26.8743V39.8594Z"
              fill="white"
            />
            <path
              d="M16.1342 33.3669L8.34216 26.8744L16.1342 20.3819V33.3669Z"
              fill="white"
            />
            <path
              d="M16.1342 19.5552L8.34216 13.0627L16.1342 6.57012V19.5552Z"
              fill="white"
            />
          </g>
        </svg>
        <p className=" fs-32 text-white m-0 fw-600">{props.title}</p>
      </div>
    </>
  );
};

export default BannerIndeices;
