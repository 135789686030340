import React from "react";
// import './StockIndices.css'
import chart from "../../assets/images/chart.png";
import AmChartsLineChart from "../IndicesCharts/AmChartsLine";
import "./StockIndices1.css";
const StockIndices1 = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 p-0">
          <div class="main-content pt-24 pl-24 pr-70 pb-64 rounded-l-t h-mainScreen wrap-pages">
            <section
              class="block-text-two block-top d-flex align-items-center justify-content-between px-24"
              style={{ marginTop: 0 }}
            >
              <div>
                <span class="block-text  mr-24">
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_676_2909"
                      style={{ maskType: "luminance" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="25"
                      height="40"
                    >
                      <path
                        d="M-6.10352e-05 0L-6.10352e-05 40H24.4765L24.4765 0H-6.10352e-05Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_676_2909)">
                      <path
                        d="M7.79515 26.4609L-6.10352e-05 19.9684L7.79515 13.4759L7.79515 26.4609Z"
                        fill="white"
                      />
                      <path
                        d="M24.4765 26.4609L16.6812 19.9684L24.4765 13.4759V26.4609Z"
                        fill="white"
                      />
                      <path
                        d="M24.4765 13.0625L16.6812 6.56997L24.4765 0.0774431V13.0625Z"
                        fill="white"
                      />
                      <path
                        d="M24.4765 39.8594L16.6812 33.3668L24.4765 26.8743V39.8594Z"
                        fill="white"
                      />
                      <path
                        d="M16.1342 33.3667L8.34216 26.8742L16.1342 20.3816V33.3667Z"
                        fill="white"
                      />
                      <path
                        d="M16.1342 19.5552L8.34216 13.0627L16.1342 6.57012V19.5552Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  Saudi Arabian Oil Co.
                </span>
              </div>
              <div class="d-flex">
                <span class="rounded-icon mr-8">
                  <svg
                    class=""
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_676_2926)">
                      <path
                        d="M23.9355 9.19077C23.7506 8.62165 23.1982 8.23048 22.42 8.11746L16.2489 7.22063L13.4891 1.62864C13.141 0.923487 12.5983 0.519043 12 0.519043C11.4017 0.519043 10.8589 0.923487 10.5109 1.62864L7.75122 7.22059L1.57999 8.11741C0.801754 8.23043 0.249417 8.62165 0.0644925 9.19068C-0.120385 9.75975 0.0965555 10.4009 0.65958 10.9497L5.1251 15.3024L4.07091 21.4488C3.9323 22.2573 4.16771 22.7408 4.38967 23.004C4.64927 23.3119 5.02798 23.4814 5.45605 23.4814C5.77968 23.4814 6.12435 23.3866 6.48028 23.1994L12 20.2974L17.5197 23.1993C17.8757 23.3864 18.2204 23.4814 18.544 23.4814H18.5441C18.9722 23.4814 19.351 23.3119 19.6105 23.0039C19.8325 22.7408 20.0679 22.2572 19.9293 21.4487L18.8749 15.3025L23.3404 10.9498C23.9035 10.401 24.1204 9.7598 23.9355 9.19077Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_676_2926">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <span class="rounded-icon ml-8">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.1812 8.06341L17.7485 2.63064C17.3002 2.18237 16.8455 1.95508 16.3971 1.95508C15.7814 1.95508 15.0625 2.42336 15.0625 3.74317V5.59272C11.1154 5.76475 7.42941 7.38348 4.61803 10.1947C1.64034 13.1722 0.00028125 17.131 0 21.342C6.45914e-06 21.4895 0.0464101 21.6333 0.132643 21.753C0.218875 21.8727 0.340568 21.9622 0.480496 22.0089C0.620424 22.0556 0.771498 22.0572 0.912334 22.0133C1.05317 21.9694 1.17663 21.8823 1.26525 21.7644C4.58489 17.3456 9.5827 14.7343 15.0625 14.5281V16.3492C15.0625 17.6689 15.7814 18.1373 16.3971 18.1373H16.3972C16.8456 18.1373 17.3003 17.91 17.7485 17.4618L23.1812 12.0289C23.7092 11.5011 24 10.7969 24 10.0462C24 9.29556 23.7092 8.59136 23.1812 8.06341Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </section>

            <section class="level-return ">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class=" d-flex ">
                      <div class="level-counter mr-16">
                        <span class="d-block counter-number mb-8">2200</span>
                      </div>
                      <div class="fatwa ">
                        <div class="d-flex mb-8">
                          <span class="d-block fatwa-text mb-8">
                            Fatwa Indicator:
                          </span>
                          <span>
                            <svg
                              class="mr-8 ml-8"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_291_34202)">
                                <path
                                  d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                  fill="#049C6B"
                                />
                                <path
                                  d="M18.0422 7.46885C17.8772 7.3038 17.6813 7.17287 17.4657 7.08355C17.2501 6.99422 17.0191 6.94824 16.7857 6.94824C16.5523 6.94824 16.3213 6.99422 16.1057 7.08355C15.8901 7.17287 15.6942 7.3038 15.5292 7.46885L10.2352 12.7624L8.47078 10.9981C8.3058 10.833 8.10992 10.7021 7.89433 10.6128C7.67874 10.5234 7.44766 10.4775 7.2143 10.4775C6.98093 10.4775 6.74985 10.5234 6.53426 10.6128C6.31867 10.7021 6.12279 10.833 5.95781 10.9981C5.79283 11.163 5.66197 11.3589 5.57268 11.5744C5.48339 11.79 5.43744 12.021 5.43744 12.2543C5.43744 12.4876 5.48339 12.7187 5.57268 12.9342C5.66197 13.1497 5.79283 13.3456 5.95781 13.5106L8.97891 16.5317C9.14389 16.6967 9.33977 16.8276 9.55536 16.917C9.77095 17.0063 10.002 17.0523 10.2354 17.0523C10.4688 17.0523 10.6998 17.0063 10.9154 16.917C11.131 16.8276 11.3269 16.6967 11.4919 16.5317L18.0422 9.98135C18.2072 9.81638 18.338 9.62053 18.4273 9.40498C18.5166 9.18943 18.5626 8.95841 18.5626 8.7251C18.5626 8.49179 18.5166 8.26077 18.4273 8.04522C18.338 7.82967 18.2072 7.63382 18.0422 7.46885Z"
                                  fill="white"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_291_34202">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span class="fatwa-yes">Yes</span>
                          </span>
                        </div>

                        <div class="d-flex gap-4">
                          <span class="result-number">
                            <span class="text-key">Volume:</span> 13,618,401
                          </span>
                          <span class="result-number">
                            <span class="text-key">52 week range:</span> 23.61 -
                            34.86
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex align-items-center ">
                      <div class="mr-16">
                        <svg
                          width="64"
                          height="64"
                          viewBox="0 0 64 64"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M51.3333 21.333L58.1333 12.2663C58.4305 11.8702 58.6114 11.3991 58.6559 10.9058C58.7003 10.4126 58.6066 9.91672 58.3851 9.47377C58.1636 9.03082 57.8232 8.6583 57.4019 8.39794C56.9807 8.13758 56.4952 7.99967 56 7.99967H24C24 7.29243 23.719 6.61415 23.2189 6.11406C22.7188 5.61396 22.0406 5.33301 21.3333 5.33301C20.6261 5.33301 19.9478 5.61396 19.4477 6.11406C18.9476 6.61415 18.6666 7.29243 18.6666 7.99967V53.333H16C15.2927 53.333 14.6145 53.614 14.1144 54.1141C13.6143 54.6142 13.3333 55.2924 13.3333 55.9997C13.3333 56.7069 13.6143 57.3852 14.1144 57.8853C14.6145 58.3854 15.2927 58.6663 16 58.6663H26.6666C27.3739 58.6663 28.0522 58.3854 28.5523 57.8853C29.0524 57.3852 29.3333 56.7069 29.3333 55.9997C29.3333 55.2924 29.0524 54.6142 28.5523 54.1141C28.0522 53.614 27.3739 53.333 26.6666 53.333H24V34.6663H56C56.4952 34.6663 56.9807 34.5284 57.4019 34.2681C57.8232 34.0077 58.1636 33.6352 58.3851 33.1922C58.6066 32.7493 58.7003 32.2534 58.6559 31.7602C58.6114 31.267 58.4305 30.7959 58.1333 30.3997L51.3333 21.333Z"
                            fill="#049C6B"
                          />
                        </svg>
                      </div>
                      <div>
                        <span class="last-time">Last | 3:00 PM CTT</span>
                        <div class="d-flex align-items-center mt-8">
                          <span class="last-time-number mr-24">27.80</span>
                          <span class="number-flags number-flags-green">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M27.6208 24.0547C27.3242 24.3517 26.9725 24.5 26.5662 24.5H5.56646C5.15995 24.5 4.80852 24.3517 4.5116 24.0547C4.21468 23.7575 4.06635 23.4061 4.06635 22.9998C4.06635 22.5936 4.21468 22.2422 4.5116 21.9452L15.0115 11.4453C15.3087 11.1484 15.6602 10.9997 16.0663 10.9997C16.4725 10.9997 16.8243 11.1484 17.121 11.4453L27.6208 21.9453C27.9174 22.2422 28.0663 22.5936 28.0663 22.9999C28.0663 23.4061 27.9174 23.7575 27.6208 24.0547Z"
                                fill="#049C6B"
                              />
                            </svg>
                            -82.6 (-0.73%)
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <AmChartsLineChart />

            {/* <section class="chart-level">
              <div class="row">
                <div class="col-lg-12">
                  <div class="d-flex justify-content-between align-items-center mb-24">
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn chart-btn-active">
                      1 Day
                    </button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                    <button class="btn chart-btn">1 Day</button>
                  </div>
                </div>
                <div class="col-lg-12">
                  <img src={chart} class="w-100" />
                </div>
                <div class="col-lg-12">
                  <ul class="nav nav-tabs chart-tabs" id="myTabs">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-bs-toggle="tab"
                        href="#tab1"
                      >
                        Key Parameters
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#tab2">
                        Market News
                      </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-bs-toggle="tab" href="#tab3">
                        Key Constituents
                      </a>
                    </li>
                  </ul>

                  <div class="tab-content content-chart">
                    <div id="tab1" class="tab-pane fade show active">
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                    </div>
                    <div id="tab2" class="tab-pane fade">
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                    </div>
                    <div id="tab3" class="tab-pane fade">
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="row px-16 py-16">
                          <div class="col-md-3">
                            <span class="d-block text-chart-tab">
                              Prev. Close
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 ">
                            <span class="d-block text-chart-tab">Open</span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 px-16">
                            <span class="d-block text-chart-tab">
                              Day Range
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 px-16">
                            <span class="d-block text-chart-tab">
                              Day Range
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                        </div>
                        <div class="row px-16 py-16">
                          <div class="col-md-3">
                            <span class="d-block text-chart-tab">
                              Prev. Close
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 ">
                            <span class="d-block text-chart-tab">Open</span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 px-16">
                            <span class="d-block text-chart-tab">
                              Day Range
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                          <div class="col-md-3 px-16">
                            <span class="d-block text-chart-tab">
                              Day Range
                            </span>
                            <span class="text-chart-number">11,298.16</span>
                          </div>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                      <div class="row px-16 py-16">
                        <div class="col-md-3">
                          <span class="d-block text-chart-tab">
                            Prev. Close
                          </span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 ">
                          <span class="d-block text-chart-tab">Open</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                        <div class="col-md-3 px-16">
                          <span class="d-block text-chart-tab">Day Range</span>
                          <span class="text-chart-number">11,298.16</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <section class="indices-tab">
              <div class="daily-reports-tabs">
                <nav class="container-tab" style={{ marginBottom: "40px" }}>
                  <ul
                    class="nav nav-tabs d-flex justify-content-between bg-primary-color"
                    id="nav-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="trend-update-tab"
                        data-bs-toggle="tab"
                        href="#trend-tab"
                        role="tab"
                        aria-controls="trend-tab"
                        aria-selected="true"
                      >
                        Trade Updates
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="financials-tab"
                        data-bs-toggle="tab"
                        href="#financials"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                      >
                        Financials
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="announcements-corprate-action-tab"
                        data-bs-toggle="tab"
                        href="#announcements-corprate-action"
                        role="tab"
                        aria-controls="announcements-corprate-action"
                        aria-selected="false"
                      >
                        Announcements & Corporate Action
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="market-data-financial-ratio-tab"
                        data-bs-toggle="tab"
                        href="#market-data-financial-ratio"
                        role="tab"
                        aria-controls="market-data-financial-ratio"
                        aria-selected="false"
                      >
                        {" "}
                        Market Data & Financial Ratio
                      </a>
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="tab2-tab"
                        data-bs-toggle="tab"
                        href="#tab2"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                      >
                        Shareholding{" "}
                      </a>
                    </li>
                  </ul>
                </nav>

                <div class="tab-content" id="main-tabs-content">
                  <div
                    class="tab-pane fade show active"
                    id="trend-tab"
                    role="tabpanel"
                    aria-labelledby="trend-update-tab"
                  >
                    <div class="row" style={{ margin: "40px 0" }}>
                      <div class="col-lg-4 h-auto">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            Last Trade
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Price</span>
                            <span class="number-trade">1,707,190.79</span>
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Change</span>
                            <span class="number-trade">1,7%</span>
                          </div>
                          <div class="d-flex justify-content-between my-8 py-16">
                            <span class="text-trade">Volume Traded.00</span>
                            <span class="number-trade">34.00</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 h-auto">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            Best Bid
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Price</span>
                            <span class="number-trade">1,707,190.79</span>
                          </div>
                          <div class="d-flex justify-content-between my-8 py-16">
                            <span class="text-trade">Volume Traded.00</span>
                            <span class="number-trade">4,969</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 h-auto">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            Best Bid
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Price</span>
                            <span class="number-trade">1,707,190.79</span>
                          </div>
                          <div class="d-flex justify-content-between my-8 py-16">
                            <span class="text-trade">Volume Traded.00</span>
                            <span class="number-trade">4,969</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" style={{ margin: "40px 0" }}>
                      <div class="col-lg-6">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            52 WEEK
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">High</span>
                            <span class="number-trade">35.15</span>
                            <span class="trade-number">2023/08/28</span>
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Low</span>
                            <span class="number-trade">27.28</span>
                            <span class="trade-number">2022/12/12</span>
                          </div>
                          <div class="d-flex justify-content-between my-8 py-16">
                            <span class="text-trade">Change**</span>
                            <span class="number-trade"></span>
                            <span class="number-trade">27.28</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            PERFORMANCE
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Start of Year</span>
                            <span class="number-trade">29.46</span>
                          </div>
                          <div
                            class="d-flex justify-content-between my-8 py-16 "
                            style={{ borderBottom: "1px solid #6F6F6F" }}
                          >
                            <span class="text-trade">Year ago</span>
                            <span class="number-trade">33.78</span>
                          </div>
                          <div class="d-flex justify-content-between my-8 py-16">
                            <span class="text-trade">3 Years ago </span>
                            <span class="number-trade">29.64</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="financials"
                    role="tabpanel"
                    aria-labelledby="financials"
                  >
                    <div>
                      <div class="table-responsive">
                        <table class="tabs-table table  balance-sheet-table ">
                          <thead class="table-head">
                            <tr class="bg-white ">
                              <th scope="col" class="head-name-th">
                                Balance Sheet
                              </th>
                              <th scope="col" class="">
                                2022-12-31
                              </th>
                              <th scope="col" class="">
                                2021-12-31
                              </th>
                              <th scope="col" class="">
                                2020-12-31
                              </th>
                              <th scope="col" class="">
                                2019-12-31
                              </th>
                            </tr>
                          </thead>
                          <tbody class="table-body">
                            <tr class="bg-white">
                              <td class="text-td ">Current Assets</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Inventory</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Investments</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Other Assets</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Total Assets</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Current Liabilities</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Non-Current Liabilities</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Other Liabilities</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Shareholders Equity</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">
                                Total Liabilities and Shareholder Equity
                              </td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                              <td class="text-green">522,714</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">Minority Interests</td>
                              <td class="text-green">-</td>
                              <td class="text-green">-</td>
                              <td class="text-green">-</td>
                              <td class="text-green">-</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">All Figures in</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">All Figures in</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                            </tr>
                            <tr class="bg-white">
                              <td class="text-td ">All Figures in</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                              <td class="text-purpile">Thousands</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="announcements-corprate-action"
                    role="tabpanel"
                    aria-labelledby="announcements-corprate-action"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            Announcements
                          </div>
                          <div>
                            <span class="text-news">
                              Top 5 Companies Trading Near Breakout Level - 22
                              Aug 2023
                            </span>
                            <p class="text-p-news my-8">
                              The Exchange has received the disclosure under
                              Regulation 29(1) of SEBI (Substantial Acquisition
                              of Shares & Takeovers) Regulations 2011 for
                              Reliance Industrial Investments & Holdings Ltd
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="text-number-action">222</span>
                              <span class="text-action">
                                SAUDI ARAMC<span class="d-block"> O 0.0 %</span>
                              </span>
                              <span class="date-action">
                                1445/01/20 - 07/08/2023{" "}
                                <span class="d-block">09:40:03</span>
                              </span>
                            </div>
                          </div>
                          <hr class="text-hr" />
                          <div>
                            <span class="text-news">
                              Top 5 Companies Trading Near Breakout Level - 22
                              Aug 2023
                            </span>
                            <p class="text-p-news my-8">
                              The Exchange has received the disclosure under
                              Regulation 29(1) of SEBI (Substantial Acquisition
                              of Shares & Takeovers) Regulations 2011 for
                              Reliance Industrial Investments & Holdings Ltd
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="text-number-action">222</span>
                              <span class="text-action">
                                SAUDI ARAMC<span class="d-block"> O 0.0 %</span>
                              </span>
                              <span class="date-action">
                                1445/01/20 - 07/08/2023{" "}
                                <span class="d-block">09:40:03</span>
                              </span>
                            </div>
                          </div>
                          <button class="btn btn-outline btn-view-more mt-24 w-100">
                            View More
                          </button>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="card-trade px-24 py-24">
                          <div class="card-head-title text-center mb-24">
                            Corporate Actions
                          </div>
                          <div>
                            <span class="text-news">
                              Top 5 Companies Trading Near Breakout Level - 22
                              Aug 2023
                            </span>
                            <p class="text-p-news my-8">
                              The Exchange has received the disclosure under
                              Regulation 29(1) of SEBI (Substantial Acquisition
                              of Shares & Takeovers) Regulations 2011 for
                              Reliance Industrial Investments & Holdings Ltd
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="text-number-action">222</span>
                              <span class="text-action">
                                SAUDI ARAMC<span class="d-block"> O 0.0 %</span>
                              </span>
                              <span class="date-action">
                                1445/01/20 - 07/08/2023{" "}
                                <span class="d-block">09:40:03</span>
                              </span>
                            </div>
                          </div>
                          <hr class="text-hr" />
                          <div>
                            <span class="text-news">
                              Top 5 Companies Trading Near Breakout Level - 22
                              Aug 2023
                            </span>
                            <p class="text-p-news my-8">
                              The Exchange has received the disclosure under
                              Regulation 29(1) of SEBI (Substantial Acquisition
                              of Shares & Takeovers) Regulations 2011 for
                              Reliance Industrial Investments & Holdings Ltd
                            </p>
                            <div class="d-flex justify-content-between align-items-center">
                              <span class="text-number-action">222</span>
                              <span class="text-action">
                                SAUDI ARAMC<span class="d-block"> O 0.0 %</span>
                              </span>
                              <span class="date-action">
                                1445/01/20 - 07/08/2023{" "}
                                <span class="d-block">09:40:03</span>
                              </span>
                            </div>
                          </div>
                          <button class="btn btn-outline btn-view-more mt-24 w-100">
                            View More
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="market-data-financial-ratio"
                    role="tabpanel"
                    aria-labelledby="market-data-financial-ratio"
                  >
                    <div class="row justify-content-between">
                      <div class="col-lg-6">
                        <div className="market-data-financial-ratio-card card-trade h-full ">
                          <div class="card-head-title">Market Data</div>
                          <div class="market-data-financial-ratio-content w-full">
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Last Trade</div>
                                <div class="number-trade">94.30</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Volume</div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Change</div>
                                <div class="text-green">5.40</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Turnover</div>
                                <div class="number-trade">154,966,027.00</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Change (%)</div>
                                <div class="text-green">5.40</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Volume</div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Open</div>
                                <div class="number-trade">94.30</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Market Value</div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Low</div>
                                <div class="number-trade">94.30</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Avg. Volume (3M)</div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">High</div>
                                <div class="number-trade">94.30</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Avg. Turnover (3M)</div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Prev. Close</div>
                                <div class="text-orange">88.90</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div
                                  style={{
                                    color: "#1D1A39",
                                    fontSize: "16px",
                                    fontFamily: "Cairo",
                                    fontWeight: "500",
                                    lineHeight: "28px",
                                    wordWrap: "break-word",
                                  }}
                                >
                                  Avg. Transaction (3M)
                                </div>
                                <div class="number-trade">1,678,131</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Change (3M)</div>
                                <div class="text-green">46.46 %</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Change(12M)</div>
                                <div class="text-green">46.46 %</div>
                              </div>
                            </div>
                            <div class="market-data-financial-ratio-row">
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">Change (6M)</div>
                                <div class="text-green">113.45 %</div>
                              </div>
                              <div class="market-data-financial-ratio-row-data">
                                <div class="text-trade">YTD</div>
                                <div class="text-green">46.46 %</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-lg-6">
                        <div className="market-data-financial-ratio-card card-trade">
                          <div className="card-head-title">
                            Financial Ratios
                          </div>
                          <div class="market-data-financial-ratio-content w-full">
                            <div className="market-data-financial-ratio-row justify-content-end">
                              <div className="number-trade">Current</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Market Cap (M Riyal)
                              </div>
                              <div className="number-trade">942.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Shares Outstanding ((M))
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                EPS ( Riyal) (TTM)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Book Value (BV) ( Riyal) (Latest announcement)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Par Value ( Riyal)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Adjusted P/E (Last12)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">Price/book</div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Dividend Yield (%) (Last Year)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Return on Average Assets (%) (TTM)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Return on Average Equity (%) (TTM)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>

                            <div className="market-data-financial-ratio-row justify-content-between align-items-center">
                              <div className="text-trade">
                                Enterprise Value (EV) (M)
                              </div>
                              <div className="number-trade">10.00</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockIndices1;
