import React from "react";
import { NavLink,Outlet } from "react-router-dom";
import styles from "../SubTab.module.css";

function StocksTab() {
  return (
    <div>
      <nav>
        <ul className={`${styles.subTabsList}`}>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              to="/StockIndices/stocks/major"
            >
              Major
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              to="/StockIndices/stocks/gainers"
            >
              Gainers
            </NavLink>
          </li>
          <li>
            <NavLink
              className={({ isActive }) =>
                isActive ? styles.active : undefined
              }
              to="/StockIndices/stocks/losers"
            >
              Losers
            </NavLink>
          </li>
        </ul>
      </nav>
      <Outlet/>
    </div>
  );
}

export default StocksTab;
