import React from 'react';
import styles from './Banner.module.css'
const Banner = (props) => {
    return (
        <>
            {/*Elevate Your Experience: Unlock Premium Access for In-Depth Stock Reports and Unparalleled Insights!*/}
            {/* <!--Start Block-text Section -->*/}
            <section className={`${styles['block-text-one']} d-flex align-items-center justify-content-center`}>
                <div>
                    <span className={`${styles['block-text']} mr-24`}>{props.title}</span>
                    <button className={`btn ${styles['block-button']} px-24`}>{props.linkText}</button>
                </div>
            </section>
            {/* <!--End Block-text Section -->*/}
        </>
    );
};

export default Banner;