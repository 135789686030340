import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';
import 'chart.js/auto';
import axios from "axios";

const BarChart = (props) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        // Define the API URL you want to fetch data from
        const apiUrl = 'https://eodhd.com/api/eod/1010.SR?fmt=json&api_token=6523dd3ad3c6c2.14907660&period=d&from=2023-01-01&to=2023-10-09';

        // Make a GET request using Axios
        axios
            .get(apiUrl)
            .then((response) => {
                // Assuming the response data structure matches the data you provided
                setData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        const ctx = chartRef.current;

        // Destroy the existing chart if it exists
        if (chartInstance.current) {
            chartInstance.current.destroy();
        }

        // Extract dates and close prices from the data
        const dates = data.map((item) => item.date);
        const closePrices = data.map((item) => item.close);
        const openPrices = data.map((item) => item.open);
        const highPrices = data.map((item) => item.high);
        const lowPrices = data.map((item) => item.low);
        const adjustedClosePrices = data.map((item) => item.adjusted_close);

        // Create your new chart instance with dynamic labels
        chartInstance.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels: dates, // Use dates as labels
                datasets: [
                    {
                        label: 'Close Price',
                        data: closePrices,
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        borderColor: 'rgba(75, 192, 192, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: 'Open Price',
                        data: openPrices,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgba(255, 99, 132, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: 'High Price',
                        data: highPrices,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: 'Low Price',
                        data: lowPrices,
                        backgroundColor: 'rgba(255, 206, 86, 0.2)',
                        borderColor: 'rgba(255, 206, 86, 1)',
                        borderWidth: 1,
                    },
                    {
                        label: 'Adjusted Close',
                        data: adjustedClosePrices,
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        borderColor: 'rgba(153, 102, 255, 1)',
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true,
                    },
                },
            },
        });

        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, [data]);

    return (
        <div>
            <canvas ref={chartRef} width="400" height="200" />
        </div>
    );
};

export default BarChart;
