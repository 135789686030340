import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "./components/Home/Home";
import Layout from "./components/Layout/Layout";
import IndicesDetails from "./components/IndicesCharts/IndicesDetails";
import BarChart from "./components/BarChart/BarChart";
import AmChartsLine from "./components/IndicesCharts/AmChartsLine";
import DailyReports from "./components/DailyReports/DailyReports";
import StockIndices from "./components/StockIndices/StockIndices";
import StockIndices1 from "./components/StockIndices1/StockIndices1.jsx";
import UpdateTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/UpdateTab";
import NewsTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/NewsTabContent/NewsTab";
import AnnouncementTab from "./components/DailyReports/DailyReportsTabs/UpdateTab/AnnouncementTabContent/AnnouncementTab";
import IndicesTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/IndicesTab";
import MajorTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/MajorTabContent/MajorTab";
import GainersTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/GainersTabContent/GainersTab";
import LosersTab from "./components/DailyReports/DailyReportsTabs/IndicesTab/LosersTabContent/LosersTab";
import StocksMajorTab from "./components/StockIndices/StockIndicesTabs/StocksTab/MajorTabContent/StocksMajorTab";
import StocksLosersTab from "./components/StockIndices/StockIndicesTabs/StocksTab/LosersTabContent/StocksLosersTab";
import StocksGainersTab from "./components/StockIndices/StockIndicesTabs/StocksTab/GainersTabContent/StocksGainersTab";
import StocksTab from "./components/StockIndices/StockIndicesTabs/StocksTab/StocksTab";
import CompaniesList from "./components/Companies/CompaniesList";
import CompanyDetails from "./components/Companies/CompanyDetails";
import StockDetails from "./components/Stocks/StockDetails";
import NotFound from "./components/NotFound/NotFound";
import { useEffect } from "react";
import StocksList from "./components/Stocks/StocksList";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
function App() {
  let routes = createBrowserRouter([
    {
      path: "",
      element: <Layout />,
      children: [
        { path: "home", element: <Home /> },
        { path: "/", element: <Home /> },
        // Daily Reports and Tabs Routes
        {
          path: "dailyReports",
          element: <DailyReports />,
          children: [
            {
              path: "updates",
              element: <UpdateTab />,
              children: [
                { path: "news", element: <NewsTab /> },
                { path: "announcements", element: <AnnouncementTab /> },
                {
                  index: true,
                  element: <Navigate to="news" replace />,
                },
              ],
            },

            {
              path: "indices",
              element: <IndicesTab />,
              children: [
                { path: "major", element: <MajorTab /> },
                { path: "gainers", element: <GainersTab /> },
                { path: "losers", element: <LosersTab /> },
                {
                  index: true,
                  element: <Navigate to="major" replace />,
                },
              ],
            },

            {
              index: true,
              element: <Navigate to="updates/news" replace />,
            },
          ],
        },

        {
          path: "StockIndices",
          element: <StockIndices />,
          children: [
            {
              path: "stocks",
              element: <StocksTab />,
              children: [
                { path: "major", element: <StocksMajorTab /> },
                { path: "gainers", element: <StocksLosersTab /> },
                { path: "losers", element: <StocksGainersTab /> },
                {
                  index: true,
                  element: <Navigate to="major" replace />,
                },
              ],
            },

            {
              index: true,
              element: <Navigate to="stocks/major" replace />,
            },
          ],
        },

        { path: "StockIndices1", element: <StockIndices1 /> },
        { path: "indices", element: <IndicesDetails /> },
        { path: "bar", element: <BarChart /> },
        { path: "charts", element: <AmChartsLine /> },
        { path: "companies", element: <CompaniesList /> },
        { path: "companies/:code", element: <CompanyDetails /> },
        { path: "stocks", element: <StocksList /> },
        { path: "stocks/:code", element: <StockDetails /> },

        // { path: "companies/:code/updates", element: <Updates /> },
      ],
    },
    { path: "*", element: <NotFound /> },
  ]);
  return (
    <RouterProvider router={routes}>
      <ScrollToTop />
    </RouterProvider>
  );
}

export default App;
