import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import styles from "./Financials.module.css";
const Financials = () => {
  const { code } = useParams();

  const [financialsData, setFinancialsData] = useState(null);
  const [dates, setDates] = useState([]); // State to store the dates

  // useEffect(() => {
  //   axios
  //     .get(
  //       `https://eodhd.com/api/fundamentals/${code}.SR?api_token=6523dd3ad3c6c2.14907660`,
  //     )
  //     .then((response) => {
  //       const quarterlyData = response.data.Financials.Balance_Sheet.yearly;
  //       setFinancialsData(quarterlyData);
  //
  //       // Extract dates from the API response
  //       const dateKeys = Object.keys(quarterlyData);
  //       setDates(dateKeys);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching company data:", error);
  //     });
  // }, [code]);
  useEffect(() => {
    axios
      .get(
        `https://eodhd.com/api/fundamentals/${code}.SR?api_token=6523dd3ad3c6c2.14907660`,
      )
      .then((response) => {
        const yearlyData = response.data.Financials.Balance_Sheet.yearly;

        // Extract dates from the API response
        const dateKeys = Object.keys(yearlyData);

        // Filter the data to include only the last 5 years
        const last5YearsData = dateKeys.slice(-5).reduce((result, year) => {
          result[year] = yearlyData[year];
          return result;
        }, {});

        setFinancialsData(last5YearsData);
        setDates(Object.keys(last5YearsData));
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, [code]);

  return (
    <div className="table-responsive">
      {financialsData && (
        <table className="table table-hover table-finance">
          <thead>
            <tr>
              <th
                className={`${styles["th-head"]}`}
                style={{ textAlign: "left" }}
              >
                Balance Sheet
              </th>
              {dates.map((date) => (
                <th className={`${styles["th-head"]}`} key={date}>
                  {date}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={`${styles["first-td"]}`}>Total Assets</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>Intangible Assets</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].intangibleAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>other Current Assets </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].otherCurrentAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>earning Assets </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].earningAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}> total Liab </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalLiab || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                total Stockholder Equity
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalStockholderEquity || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>deferredLongTermLiab</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].deferredLongTermLiab || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>other Current Liab</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].otherCurrentLiab || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>common Stock</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].commonStock}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>capital Stock</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].capitalStock || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>retained Earnings</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].retainedEarnings || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>other Liab</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].otherLiab || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>good Will</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].goodWill || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>other Assets</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].otherAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>cash</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].cash || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>cash And Equivalents</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].cashAndEquivalents || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                total Current Liabilities
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalCurrentLiabilities || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                current Deferred Revenue
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].currentDeferredRevenue || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>net Debt</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].netDebt || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>short Term Debt</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortTermDebt || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>short Long Term Debt</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortLongTermDebt || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                short Long Term Debt Total
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortLongTermDebtTotal || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                other Stock holder Equity
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].otherStockholderEquity || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                property Plant Equipment
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].propertyPlantEquipment || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>total Current Assets</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalCurrentAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>long Term Investments</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].longTermInvestments || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>net Tangible Assets</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].netTangibleAssets || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                short Term Investments
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortTermInvestments || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                short Term Investments
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortTermInvestments || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>net Receivables</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].netReceivables || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>long Term Debt</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].longTermDebt || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>inventory</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].inventory || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>accounts Payable</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].accountsPayable || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                total Permanent Equity
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].totalPermanentEquity || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                non controlling Interest In ConsolidatedEntity
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date]
                    .noncontrollingInterestInConsolidatedEntity || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                non Current Assets Total
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].nonCurrentAssetsTotal || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>long Term Debt Total</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].longTermDebtTotal || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                short Term Investments
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].shortTermInvestments || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                non Current Liabilities Total
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].nonCurrentLiabilitiesTotal || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                liabilities And Stockholders Equity
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].liabilitiesAndStockholdersEquity || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                cash And ShortTerm Investments
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].cashAndShortTermInvestments || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                property Plant And EquipmentNet
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].propertyPlantAndEquipmentNet || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>ne tInvested Capital</td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].netInvestedCapital || "-"}
                </td>
              ))}
            </tr>
            <tr>
              <td className={`${styles["first-td"]}`}>
                common Stock Shares Outstanding
              </td>
              {dates.map((date) => (
                <td className={`${styles["td-number"]}`} key={date}>
                  {financialsData[date].commonStockSharesOutstanding || "-"}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Financials;
