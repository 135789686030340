import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./StockIndicesTabs.module.css";

const StockIndicesTabs = () => {
  return (
    <nav className={`${styles.dailyTabsNav}`}>
      <ul className={`${styles.dailyTabsList}`}>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/prices"
          >
            Prices
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/stocks"
          >
            Stocks
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/stocks-technicals"
          >
            Stocks Technicals
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/aggregated-deliveries"
          >
            Aggregated Deliveries
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/futures-OI"
          >
            Futures OI
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/options-OI"
          >
            Options OI
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/deals"
          >
            Deals
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/StockIndices/results"
          >
            Results
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default StockIndicesTabs;
