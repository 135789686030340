import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import styles from "./Technicals.module.css";
const Technicals = () => {
  const { code } = useParams();

  let [technicals, setTechnicals] = useState(null);

  useEffect(() => {
    axios
      .get(
        `https://eodhd.com/api/fundamentals/${code}.SR?api_token=6523dd3ad3c6c2.14907660`,
      )
      .then((response) => {
        setTechnicals(response.data);
      })
      .catch((error) => {
        console.error("Error fetching news data:", error);
      });
  }, []);

  return (
    <>
      <h2 className="text-center my-3">Technicals</h2>
      {technicals ? (
        <div className="technicals-container">
          <span className={`${styles["tec-details"]}`}>
            Beta: {technicals.Technicals.Beta}
          </span>
          <span className={`${styles["tec-details"]}`}>
            52 Week High: {technicals.Technicals["52WeekHigh"]}
          </span>
          <span className={`${styles["tec-details"]}`}>
            52 Week Low: {technicals.Technicals["52WeekLow"]}
          </span>
          <span className={`${styles["tec-details"]}`}>
            50 Day Moving Average: {technicals.Technicals["50DayMA"]}
          </span>
          <span className={`${styles["tec-details"]}`}>
            200 Day Moving Average: {technicals.Technicals["200DayMA"]}
          </span>
          <span className={`${styles["tec-details"]}`}>
            Shares Short: {technicals.Technicals.SharesShort}
          </span>
          <span className={`${styles["tec-details"]}`}>
            Shares Short Prior Month:{" "}
            {technicals.Technicals.SharesShortPriorMonth}
          </span>
          <span className={`${styles["tec-details"]}`}>
            Short Ratio: {technicals.Technicals.ShortRatio}
          </span>
          <span className={`${styles["tec-details"]}`}>
            Short Percent: {technicals.Technicals.ShortPercent}
          </span>
        </div>
      ) : (
        <span>Loading technicals data...</span>
      )}
    </>
  );
};

export default Technicals;
