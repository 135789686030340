import React, { useState } from "react";
import "./Tabs.css";
import { Tab, Tabs } from "react-bootstrap";

const HomeTab = (props) => {
  const [key, setKey] = useState("home");
  return (
    <>
      <section className="delivery-scans ">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="delivery-scans-wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className="head-content ">
                    <h2>High Delivery Scans</h2>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ul
                    className="home-tabs nav nav-tabs mt-24 mb-16"
                    id="myTabs"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="tab1-tab"
                        data-bs-toggle="tab"
                        href="#tab1"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                      >
                        Quantity
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="tab2-tab"
                        data-bs-toggle="tab"
                        href="#tab2"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                      >
                        Percentage
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabsContent">
                    <div
                      className="tab-pane fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="tab1-tab"
                    >
                      <span className="tab-date">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_212_36945)">
                            <path
                              d="M14.625 1.86987H13.875V0.619873H12.625V1.86987H4.375V0.619873H3.125V1.86987H2.375C1.34113 1.86987 0.5 2.711 0.5 3.74487V14.7449C0.5 15.7787 1.34113 16.6199 2.375 16.6199H14.625C15.6589 16.6199 16.5 15.7787 16.5 14.7449V3.74487C16.5 2.711 15.6589 1.86987 14.625 1.86987ZM15.25 14.7449C15.25 15.0895 14.9696 15.3699 14.625 15.3699H2.375C2.03038 15.3699 1.75 15.0895 1.75 14.7449V6.49487H15.25V14.7449ZM15.25 5.24487H1.75V3.74487C1.75 3.40025 2.03038 3.11987 2.375 3.11987H3.125V4.36987H4.375V3.11987H12.625V4.36987H13.875V3.11987H14.625C14.9696 3.11987 15.25 3.40025 15.25 3.74487V5.24487Z"
                              fill="#1D1A39"
                            />
                            <path
                              d="M2.875 7.80737H4.125V9.05737H2.875V7.80737ZM5.375 7.80737H6.625V9.05737H5.375V7.80737ZM7.875 7.80737H9.125V9.05737H7.875V7.80737ZM10.375 7.80737H11.625V9.05737H10.375V7.80737ZM12.875 7.80737H14.125V9.05737H12.875V7.80737ZM2.875 10.3074H4.125V11.5574H2.875V10.3074ZM5.375 10.3074H6.625V11.5574H5.375V10.3074ZM7.875 10.3074H9.125V11.5574H7.875V10.3074ZM10.375 10.3074H11.625V11.5574H10.375V10.3074ZM2.875 12.8074H4.125V14.0574H2.875V12.8074ZM5.375 12.8074H6.625V14.0574H5.375V12.8074ZM7.875 12.8074H9.125V14.0574H7.875V12.8074ZM10.375 12.8074H11.625V14.0574H10.375V12.8074ZM12.875 10.3074H14.125V11.5574H12.875V10.3074Z"
                              fill="#1D1A39"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_212_36945">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5 0.619873)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="ml-8"> 23 Aug 2023</span>
                      </span>
                      <ul className="tab-ul">
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab2"
                      role="tabpanel"
                      aria-labelledby="tab2-tab"
                    >
                      <span className="tab-date">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_212_36945)">
                            <path
                              d="M14.625 1.86987H13.875V0.619873H12.625V1.86987H4.375V0.619873H3.125V1.86987H2.375C1.34113 1.86987 0.5 2.711 0.5 3.74487V14.7449C0.5 15.7787 1.34113 16.6199 2.375 16.6199H14.625C15.6589 16.6199 16.5 15.7787 16.5 14.7449V3.74487C16.5 2.711 15.6589 1.86987 14.625 1.86987ZM15.25 14.7449C15.25 15.0895 14.9696 15.3699 14.625 15.3699H2.375C2.03038 15.3699 1.75 15.0895 1.75 14.7449V6.49487H15.25V14.7449ZM15.25 5.24487H1.75V3.74487C1.75 3.40025 2.03038 3.11987 2.375 3.11987H3.125V4.36987H4.375V3.11987H12.625V4.36987H13.875V3.11987H14.625C14.9696 3.11987 15.25 3.40025 15.25 3.74487V5.24487Z"
                              fill="#1D1A39"
                            />
                            <path
                              d="M2.875 7.80737H4.125V9.05737H2.875V7.80737ZM5.375 7.80737H6.625V9.05737H5.375V7.80737ZM7.875 7.80737H9.125V9.05737H7.875V7.80737ZM10.375 7.80737H11.625V9.05737H10.375V7.80737ZM12.875 7.80737H14.125V9.05737H12.875V7.80737ZM2.875 10.3074H4.125V11.5574H2.875V10.3074ZM5.375 10.3074H6.625V11.5574H5.375V10.3074ZM7.875 10.3074H9.125V11.5574H7.875V10.3074ZM10.375 10.3074H11.625V11.5574H10.375V10.3074ZM2.875 12.8074H4.125V14.0574H2.875V12.8074ZM5.375 12.8074H6.625V14.0574H5.375V12.8074ZM7.875 12.8074H9.125V14.0574H7.875V12.8074ZM10.375 12.8074H11.625V14.0574H10.375V12.8074ZM12.875 10.3074H14.125V11.5574H12.875V10.3074Z"
                              fill="#1D1A39"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_212_36945">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5 0.619873)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="ml-8"> 23 Aug 2023</span>
                      </span>
                      <ul className="tab-ul">
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>
                            Top 5 Companies Trading Near Breakout Level
                          </span>
                          <span className="x-arrow">12.2 X</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-sm-12">
            <div className="delivery-scans-wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className="head-content ">
                    <h2>Future Open Intrest Scans</h2>
                  </div>
                </div>
                <div className="col-lg-12">
                  <ul
                    className="home-tabs nav nav-tabs mt-24 mb-16"
                    id="myTabs"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        id="Increasing-tab"
                        data-bs-toggle="tab"
                        href="#Increasing"
                        role="tab"
                        aria-controls="Increasing"
                        aria-selected="true"
                      >
                        Increasing OI
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        id="Decreasing-tab"
                        data-bs-toggle="tab"
                        href="#Decreasing"
                        role="tab"
                        aria-controls="Decreasing"
                        aria-selected="false"
                      >
                        Decreasing OI
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabsContent">
                    <div
                      className="tab-pane fade show active"
                      id="Increasing"
                      role="tabpanel"
                      aria-labelledby="Increasing-tab"
                    >
                      <span className="tab-date">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_212_36945)">
                            <path
                              d="M14.625 1.86987H13.875V0.619873H12.625V1.86987H4.375V0.619873H3.125V1.86987H2.375C1.34113 1.86987 0.5 2.711 0.5 3.74487V14.7449C0.5 15.7787 1.34113 16.6199 2.375 16.6199H14.625C15.6589 16.6199 16.5 15.7787 16.5 14.7449V3.74487C16.5 2.711 15.6589 1.86987 14.625 1.86987ZM15.25 14.7449C15.25 15.0895 14.9696 15.3699 14.625 15.3699H2.375C2.03038 15.3699 1.75 15.0895 1.75 14.7449V6.49487H15.25V14.7449ZM15.25 5.24487H1.75V3.74487C1.75 3.40025 2.03038 3.11987 2.375 3.11987H3.125V4.36987H4.375V3.11987H12.625V4.36987H13.875V3.11987H14.625C14.9696 3.11987 15.25 3.40025 15.25 3.74487V5.24487Z"
                              fill="#1D1A39"
                            />
                            <path
                              d="M2.875 7.80737H4.125V9.05737H2.875V7.80737ZM5.375 7.80737H6.625V9.05737H5.375V7.80737ZM7.875 7.80737H9.125V9.05737H7.875V7.80737ZM10.375 7.80737H11.625V9.05737H10.375V7.80737ZM12.875 7.80737H14.125V9.05737H12.875V7.80737ZM2.875 10.3074H4.125V11.5574H2.875V10.3074ZM5.375 10.3074H6.625V11.5574H5.375V10.3074ZM7.875 10.3074H9.125V11.5574H7.875V10.3074ZM10.375 10.3074H11.625V11.5574H10.375V10.3074ZM2.875 12.8074H4.125V14.0574H2.875V12.8074ZM5.375 12.8074H6.625V14.0574H5.375V12.8074ZM7.875 12.8074H9.125V14.0574H7.875V12.8074ZM10.375 12.8074H11.625V14.0574H10.375V12.8074ZM12.875 10.3074H14.125V11.5574H12.875V10.3074Z"
                              fill="#1D1A39"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_212_36945">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5 0.619873)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="ml-8"> 23 Aug 2023</span>
                      </span>
                      <ul className="tab-ul">
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="Decreasing"
                      role="tabpanel"
                      aria-labelledby="Decreasing-tab"
                    >
                      <span className="tab-date">
                        <svg
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_212_36945)">
                            <path
                              d="M14.625 1.86987H13.875V0.619873H12.625V1.86987H4.375V0.619873H3.125V1.86987H2.375C1.34113 1.86987 0.5 2.711 0.5 3.74487V14.7449C0.5 15.7787 1.34113 16.6199 2.375 16.6199H14.625C15.6589 16.6199 16.5 15.7787 16.5 14.7449V3.74487C16.5 2.711 15.6589 1.86987 14.625 1.86987ZM15.25 14.7449C15.25 15.0895 14.9696 15.3699 14.625 15.3699H2.375C2.03038 15.3699 1.75 15.0895 1.75 14.7449V6.49487H15.25V14.7449ZM15.25 5.24487H1.75V3.74487C1.75 3.40025 2.03038 3.11987 2.375 3.11987H3.125V4.36987H4.375V3.11987H12.625V4.36987H13.875V3.11987H14.625C14.9696 3.11987 15.25 3.40025 15.25 3.74487V5.24487Z"
                              fill="#1D1A39"
                            />
                            <path
                              d="M2.875 7.80737H4.125V9.05737H2.875V7.80737ZM5.375 7.80737H6.625V9.05737H5.375V7.80737ZM7.875 7.80737H9.125V9.05737H7.875V7.80737ZM10.375 7.80737H11.625V9.05737H10.375V7.80737ZM12.875 7.80737H14.125V9.05737H12.875V7.80737ZM2.875 10.3074H4.125V11.5574H2.875V10.3074ZM5.375 10.3074H6.625V11.5574H5.375V10.3074ZM7.875 10.3074H9.125V11.5574H7.875V10.3074ZM10.375 10.3074H11.625V11.5574H10.375V10.3074ZM2.875 12.8074H4.125V14.0574H2.875V12.8074ZM5.375 12.8074H6.625V14.0574H5.375V12.8074ZM7.875 12.8074H9.125V14.0574H7.875V12.8074ZM10.375 12.8074H11.625V14.0574H10.375V12.8074ZM12.875 10.3074H14.125V11.5574H12.875V10.3074Z"
                              fill="#1D1A39"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_212_36945">
                              <rect
                                width="16"
                                height="16"
                                fill="white"
                                transform="translate(0.5 0.619873)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                        <span className="ml-8"> 23 Aug 2023</span>
                      </span>
                      <ul className="tab-ul">
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                        <li className="item-list d-flex justify-content-between align-items-center my-8">
                          <span>Escorts Kubota Ltd.</span>
                          <span className="arrow-number-style ">
                            <svg
                              width="8"
                              height="7"
                              viewBox="0 0 8 7"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M3.1898 1.36795C3.58562 0.774215 4.45808 0.774215 4.8539 1.36795L6.98538 4.56517C7.42842 5.22973 6.95203 6.11987 6.15333 6.11987H1.89037C1.09167 6.11987 0.615281 5.22973 1.05832 4.56517L3.1898 1.36795Z"
                                fill="#049C6B"
                              ></path>
                            </svg>{" "}
                            810%
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeTab;
