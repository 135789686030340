import React from 'react';
import styles from './DetailsBanner.module.css'
const DeatialsBanner = (props) => {
    return (<>
        {/* <!--Start Block-text Section -->*/}
        <section className={`${styles['block-text-two']} d-flex align-items-center justify-content-between px-24`}>
                <div>
                    <span className={`${styles['block-text']}  mr-24`}>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_676_2909" style={{ maskType: 'luminance' }}
                                  maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="40">
        <path d="M-6.10352e-05 0L-6.10352e-05 40H24.4765L24.4765 0H-6.10352e-05Z" fill="white"/>
      </mask>
                             <g mask="url(#mask0_676_2909)">
        <path d="M7.79515 26.4609L-6.10352e-05 19.9684L7.79515 13.4759L7.79515 26.4609Z" fill="white"/>
        <path d="M24.4765 26.4609L16.6812 19.9684L24.4765 13.4759V26.4609Z" fill="white"/>
        <path d="M24.4765 13.0625L16.6812 6.56997L24.4765 0.0774431V13.0625Z" fill="white"/>
        <path d="M24.4765 39.8594L16.6812 33.3668L24.4765 26.8743V39.8594Z" fill="white"/>
        <path d="M16.1342 33.3667L8.34216 26.8742L16.1342 20.3816V33.3667Z" fill="white"/>
        <path d="M16.1342 19.5552L8.34216 13.0627L16.1342 6.57012V19.5552Z" fill="white"/>
      </g>
                        </svg>
                        {props.name}
                    </span>
                </div>
            <div className="d-flex">
                <span className={`${styles['rounded-icon ']} mr-8`}>
                   <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_676_2926)">
                    <path
                        d="M23.9355 9.19077C23.7506 8.62165 23.1982 8.23048 22.42 8.11746L16.2489 7.22063L13.4891 1.62864C13.141 0.923487 12.5983 0.519043 12 0.519043C11.4017 0.519043 10.8589 0.923487 10.5109 1.62864L7.75122 7.22059L1.57999 8.11741C0.801754 8.23043 0.249417 8.62165 0.0644925 9.19068C-0.120385 9.75975 0.0965555 10.4009 0.65958 10.9497L5.1251 15.3024L4.07091 21.4488C3.9323 22.2573 4.16771 22.7408 4.38967 23.004C4.64927 23.3119 5.02798 23.4814 5.45605 23.4814C5.77968 23.4814 6.12435 23.3866 6.48028 23.1994L12 20.2974L17.5197 23.1993C17.8757 23.3864 18.2204 23.4814 18.544 23.4814H18.5441C18.9722 23.4814 19.351 23.3119 19.6105 23.0039C19.8325 22.7408 20.0679 22.2572 19.9293 21.4487L18.8749 15.3025L23.3404 10.9498C23.9035 10.401 24.1204 9.7598 23.9355 9.19077Z"
                        fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_676_2926">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                </span>
                <span className={`${styles['rounded-icon ']} ml-8`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M23.1812 8.06341L17.7485 2.63064C17.3002 2.18237 16.8455 1.95508 16.3971 1.95508C15.7814 1.95508 15.0625 2.42336 15.0625 3.74317V5.59272C11.1154 5.76475 7.42941 7.38348 4.61803 10.1947C1.64034 13.1722 0.00028125 17.131 0 21.342C6.45914e-06 21.4895 0.0464101 21.6333 0.132643 21.753C0.218875 21.8727 0.340568 21.9622 0.480496 22.0089C0.620424 22.0556 0.771498 22.0572 0.912334 22.0133C1.05317 21.9694 1.17663 21.8823 1.26525 21.7644C4.58489 17.3456 9.5827 14.7343 15.0625 14.5281V16.3492C15.0625 17.6689 15.7814 18.1373 16.3971 18.1373H16.3972C16.8456 18.1373 17.3003 17.91 17.7485 17.4618L23.1812 12.0289C23.7092 11.5011 24 10.7969 24 10.0462C24 9.29556 23.7092 8.59136 23.1812 8.06341Z"
      fill="white"/>
</svg>
                </span>
            </div>
        </section>
        {/* <!--End Block-text Section -->*/}

    </>);
};

export default DeatialsBanner;