import React from "react";

const TradeUpdates = () => {
  return (
    <>
      <div className="row" style={{ margin: "40px 0" }}>
        <div className="col-lg-4 h-auto">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">Last Trade</div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Price</span>
              <span className="number-trade">1,707,190.79</span>
            </div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Change</span>
              <span className="number-trade">1,7%</span>
            </div>
            <div className="d-flex justify-content-between my-8 py-16">
              <span className="text-trade">Volume Traded.00</span>
              <span className="number-trade">34.00</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 h-auto">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">Best Bid</div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Price</span>
              <span className="number-trade">1,707,190.79</span>
            </div>
            <div className="d-flex justify-content-between my-8 py-16">
              <span className="text-trade">Volume Traded.00</span>
              <span className="number-trade">4,969</span>
            </div>
          </div>
        </div>
        <div className="col-lg-4 h-auto">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">Best Bid</div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Price</span>
              <span className="number-trade">1,707,190.79</span>
            </div>
            <div className="d-flex justify-content-between my-8 py-16">
              <span className="text-trade">Volume Traded.00</span>
              <span className="number-trade">4,969</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ margin: "40px 0" }}>
        <div className="col-lg-6">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">52 WEEK</div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">High</span>
              <span className="number-trade">35.15</span>
              <span className="trade-number">2023/08/28</span>
            </div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Low</span>
              <span className="number-trade">27.28</span>
              <span className="trade-number">2022/12/12</span>
            </div>
            <div className="d-flex justify-content-between my-8 py-16">
              <span className="text-trade">Change**</span>
              <span className="number-trade"></span>
              <span className="number-trade">27.28</span>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">PERFORMANCE</div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Start of Year</span>
              <span className="number-trade">29.46</span>
            </div>
            <div
              className="d-flex justify-content-between my-8 py-16 "
              style={{ borderBottom: "1px solid #6F6F6F" }}
            >
              <span className="text-trade">Year ago</span>
              <span className="number-trade">33.78</span>
            </div>
            <div className="d-flex justify-content-between my-8 py-16">
              <span className="text-trade">3 Years ago </span>
              <span className="number-trade">29.64</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TradeUpdates;
