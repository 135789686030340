import React from "react";

const AnnouncementsCorporate = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">
              Announcements
            </div>
            <div>
              <span className="text-news">
                Top 5 Companies Trading Near Breakout Level - 22 Aug 2023
              </span>
              <p className="text-p-news my-8">
                The Exchange has received the disclosure under Regulation 29(1)
                of SEBI (Substantial Acquisition of Shares & Takeovers)
                Regulations 2011 for Reliance Industrial Investments & Holdings
                Ltd
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-number-action">222</span>
                <span className="text-action">
                  SAUDI ARAMC<span className="d-block"> O 0.0 %</span>
                </span>
                <span className="date-action">
                  1445/01/20 - 07/08/2023{" "}
                  <span className="d-block">09:40:03</span>
                </span>
              </div>
            </div>
            <hr className="text-hr" />
            <div>
              <span className="text-news">
                Top 5 Companies Trading Near Breakout Level - 22 Aug 2023
              </span>
              <p className="text-p-news my-8">
                The Exchange has received the disclosure under Regulation 29(1)
                of SEBI (Substantial Acquisition of Shares & Takeovers)
                Regulations 2011 for Reliance Industrial Investments & Holdings
                Ltd
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-number-action">222</span>
                <span className="text-action">
                  SAUDI ARAMC<span className="d-block"> O 0.0 %</span>
                </span>
                <span className="date-action">
                  1445/01/20 - 07/08/2023{" "}
                  <span className="d-block">09:40:03</span>
                </span>
              </div>
            </div>
            <button className="btn btn-outline btn-view-more mt-24 w-100">
              View More
            </button>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-trade px-24 py-24">
            <div className="card-head-title text-center mb-24">
              Corporate Actions
            </div>
            <div>
              <span className="text-news">
                Top 5 Companies Trading Near Breakout Level - 22 Aug 2023
              </span>
              <p className="text-p-news my-8">
                The Exchange has received the disclosure under Regulation 29(1)
                of SEBI (Substantial Acquisition of Shares & Takeovers)
                Regulations 2011 for Reliance Industrial Investments & Holdings
                Ltd
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-number-action">222</span>
                <span className="text-action">
                  SAUDI ARAMC<span className="d-block"> O 0.0 %</span>
                </span>
                <span className="date-action">
                  1445/01/20 - 07/08/2023{" "}
                  <span className="d-block">09:40:03</span>
                </span>
              </div>
            </div>
            <hr className="text-hr" />
            <div>
              <span className="text-news">
                Top 5 Companies Trading Near Breakout Level - 22 Aug 2023
              </span>
              <p className="text-p-news my-8">
                The Exchange has received the disclosure under Regulation 29(1)
                of SEBI (Substantial Acquisition of Shares & Takeovers)
                Regulations 2011 for Reliance Industrial Investments & Holdings
                Ltd
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <span className="text-number-action">222</span>
                <span className="text-action">
                  SAUDI ARAMC<span className="d-block"> O 0.0 %</span>
                </span>
                <span className="date-action">
                  1445/01/20 - 07/08/2023{" "}
                  <span className="d-block">09:40:03</span>
                </span>
              </div>
            </div>
            <button className="btn btn-outline btn-view-more mt-24 w-100">
              View More
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnnouncementsCorporate;
