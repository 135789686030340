import React, { useEffect, useState } from "react";
import BannerStocks from "./BannerStocks/BannerStocks";
import styles from "../StockIndicesTable/StockIndicesTable.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Papa from "papaparse";

const StocksList = () => {
  const [stocks, setStocks] = useState([]);
  useEffect(() => {
    const apiUrl =
      "https://eodhd.com/api/exchange-symbol-list/SR?api_token=6523dd3ad3c6c2.14907660";

    axios
      .get(apiUrl)
      .then((response) => {
        const csvData = response.data;

        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            const filteredStocks = result.data.filter(
              (stock) => stock.Type === "Common Stock",
            );
            setStocks(filteredStocks);
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  return (
    <>
      <BannerStocks title="Stocks List" />
      <table className={`${styles["tabs-table"]} table`}>
        <thead className={`${styles["table-head"]}`}>
          <tr className="bg-white">
            {stocks.length > 0 &&
              Object.keys(stocks[0]).map((key) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody className={`${styles["table-body"]}`}>
          {stocks.map((company, index) => (
            <tr className="bg-white" key={company.Code}>
              {Object.entries(company).map(([key, value]) => (
                <td className="text-secondary-color-10 fs-18 fw-500" key={key}>
                  {key === "Code" ? (
                    <Link
                      to={`/stocks/${value}`}
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                    >
                      {value}
                    </Link>
                  ) : (
                    value
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default StocksList;
