import React, { useEffect, useState } from "react";
import axios from "axios";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import "./CompanyDetails";
import styles from "../StockIndicesTable/StockIndicesTable.module.css";
import Banner from "../Banner/Banner";
import BannerIndeices from "./Banner/BannerIndeices";
const CompaniesList = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const apiUrl =
      "https://eodhd.com/api/exchange-symbol-list/SR?api_token=6523dd3ad3c6c2.14907660";

    axios
      .get(apiUrl)
      .then((response) => {
        const csvData = response.data;

        // Parse the CSV data using react-papaparse
        Papa.parse(csvData, {
          header: true,
          dynamicTyping: true,
          complete: (result) => {
            const filteredStocks = result.data.filter(
              (stock) => stock.Type === "INDEX",
            );
            setData(filteredStocks);
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    <>
      <BannerIndeices title="Indices" />
      <table
        className={`${styles["tabs-table"]} table`}
        style={{ marginTop: "24px" }}
      >
        <thead className={`${styles["table-head"]}`}>
          <tr className="bg-white">
            {data.length > 0 &&
              Object.keys(data[0]).map((key) => <th key={key}>{key}</th>)}
          </tr>
        </thead>
        <tbody className={`${styles["table-body"]}`}>
          {data.map((company, index) => (
            <tr className="bg-white" key={company.Code}>
              {Object.entries(company).map(([key, value]) => (
                <td className="text-secondary-color-10 fs-18 fw-500" key={key}>
                  {key === "Code" ? (
                    <Link to={`/companies/${value}`}>{value}</Link>
                  ) : (
                    value
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CompaniesList;
