import React from "react";
import styles from "./IndexLevel.module.css";
const IndexLevel = (props) => {
  return (
    <>
      {/* <!--Start First Section-->*/}
      <section className={`${styles["level-return"]}`}>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <div className={`${styles["level"]} mr-16`}>
                  <span className={`d-block ${styles["level-text"]} mb-8`}>
                    Index Level
                  </span>
                  <span className={`${styles["level-number"]}`}>
                    {props.indexNumber}
                  </span>
                </div>
                <div className={`${styles["return"]}`}>
                  <span className={`d-block ${styles["return-text"]} mb-8`}>
                    1D Return
                  </span>
                  <span className={`${styles["return-number"]}`}>
                    {props.indexReturn}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center ">
                <div className="mr-24 d-flex justify-content-center align-items-center">
                  <svg
                    width="64"
                    height="64"
                    viewBox="0 0 64 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M51.3333 21.333L58.1333 12.2663C58.4305 11.8702 58.6114 11.3991 58.6559 10.9058C58.7003 10.4126 58.6066 9.91672 58.3851 9.47377C58.1636 9.03082 57.8232 8.6583 57.4019 8.39794C56.9807 8.13758 56.4952 7.99967 56 7.99967H24C24 7.29243 23.719 6.61415 23.2189 6.11406C22.7188 5.61396 22.0406 5.33301 21.3333 5.33301C20.6261 5.33301 19.9478 5.61396 19.4477 6.11406C18.9476 6.61415 18.6666 7.29243 18.6666 7.99967V53.333H16C15.2927 53.333 14.6145 53.614 14.1144 54.1141C13.6143 54.6142 13.3333 55.2924 13.3333 55.9997C13.3333 56.7069 13.6143 57.3852 14.1144 57.8853C14.6145 58.3854 15.2927 58.6663 16 58.6663H26.6666C27.3739 58.6663 28.0522 58.3854 28.5523 57.8853C29.0524 57.3852 29.3333 56.7069 29.3333 55.9997C29.3333 55.2924 29.0524 54.6142 28.5523 54.1141C28.0522 53.614 27.3739 53.333 26.6666 53.333H24V34.6663H56C56.4952 34.6663 56.9807 34.5284 57.4019 34.2681C57.8232 34.0077 58.1636 33.6352 58.3851 33.1922C58.6066 32.7493 58.7003 32.2534 58.6559 31.7602C58.6114 31.267 58.4305 30.7959 58.1333 30.3997L51.3333 21.333Z"
                      fill="#D34645"
                    />
                  </svg>
                  <span className={`${styles["flag-number"]}`}>
                    {props.flagNumber}
                  </span>
                </div>
                <div>
                  <span className={`${styles["number-flags"]}`}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27.6208 11.4448C27.3242 11.1478 26.9725 10.9995 26.5662 10.9995H5.56646C5.15995 10.9995 4.80852 11.1478 4.5116 11.4448C4.21468 11.742 4.06635 12.0934 4.06635 12.4997C4.06635 12.9059 4.21468 13.2573 4.5116 13.5543L15.0115 24.0542C15.3087 24.3511 15.6602 24.4998 16.0663 24.4998C16.4725 24.4998 16.8243 24.3511 17.121 24.0542L27.6208 13.5542C27.9174 13.2573 28.0663 12.9059 28.0663 12.4996C28.0663 12.0934 27.9174 11.742 27.6208 11.4448Z"
                        fill="#FD2523"
                      />
                    </svg>
                    {props.numberAD}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!--End First Section-->*/}
    </>
  );
};

export default IndexLevel;
