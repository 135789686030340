import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5stock from "@amcharts/amcharts5/stock";
import * as am5xy from "@amcharts/amcharts5/xy";
import TabChartDetails from "../IndicesCharts/TabChartDetails/TabChartDetails";
import DeatialsBanner from "../IndicesCharts/DeatialsBanner/DeatialsBanner";
import axios from "axios";
import CompanyDetailsTab from "./CompanyDetailsTab";

const CompanyDetails = () => {
  const { code } = useParams();
  const chartDivRef = useRef(null);
  const rootRef = useRef(null);
  const [companyData, setCompanyData] = useState("");
  useEffect(() => {
    // Initialize the chart when the component mounts
    const chartDiv = chartDivRef.current;

    if (chartDiv) {
      const root = am5.Root.new(chartDiv);
      root.setThemes([am5themes_Animated.new(root)]);

      const stockChart = root.container.children.push(
        am5stock.StockChart.new(root, {}),
      );

      root.numberFormatter.set("numberFormat", "#,###.00");

      const mainPanel = stockChart.panels.push(
        am5stock.StockPanel.new(root, {
          wheelY: "zoomX",
          panX: true,
          panY: true,
          height: am5.percent(70),
        }),
        am5stock.SettingsControl.new(root, {
          stockChart: stockChart,
        }),
      );

      const valueAxis = mainPanel.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {
            pan: "zoom",
          }),
          tooltip: am5.Tooltip.new(root, {}),
          numberFormat: "#,###.00",
          extraTooltipPrecision: 2,
        }),
      );

      const dateAxis = mainPanel.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          groupData: true,
          renderer: am5xy.AxisRendererX.new(root, {}),
          tooltip: am5.Tooltip.new(root, {}),
        }),
      );

      const valueSeries = mainPanel.series.push(
        am5xy.CandlestickSeries.new(root, {
          name: companyData ? companyData.General?.Name || code : code, // Use the company name if available, otherwise use the code
          valueXField: "Date",
          valueYField: "Close",
          highValueYField: "High",
          lowValueYField: "Low",
          openValueYField: "Open",
          calculateAggregates: true,
          xAxis: dateAxis,
          yAxis: valueAxis,
          legendValueText: "{valueY}",
        }),
      );

      stockChart.set("stockSeries", valueSeries);

      const valueLegend = mainPanel.plotContainer.children.push(
        am5stock.StockLegend.new(root, {
          stockChart: stockChart,
        }),
      );
      valueLegend.data.setAll([valueSeries]);

      mainPanel.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          yAxis: valueAxis,
          xAxis: dateAxis,
          snapToSeries: [valueSeries],
          snapToSeriesBy: "y!",
        }),
      );

      const scrollbar = mainPanel.set(
        "scrollbarX",
        am5xy.XYChartScrollbar.new(root, {
          orientation: "horizontal",
          height: 50,
        }),
      );
      stockChart.toolsContainer.children.push(scrollbar);

      const sbDateAxis = scrollbar.chart.xAxes.push(
        am5xy.GaplessDateAxis.new(root, {
          baseInterval: {
            timeUnit: "day",
            count: 1,
          },
          renderer: am5xy.AxisRendererX.new(root, {}),
        }),
      );

      const sbValueAxis = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      );

      const sbSeries = scrollbar.chart.series.push(
        am5xy.LineSeries.new(root, {
          valueYField: "Close",
          valueXField: "Date",
          xAxis: sbDateAxis,
          yAxis: sbValueAxis,
        }),
      );

      sbSeries.fills.template.setAll({
        visible: true,
        fillOpacity: 0.3,
      });

      function loadData(companyCode, series) {
        // Construct the API URL based on the company code
        const apiUrl = `https://eodhd.com/api/eod/${companyCode}.SR?api_token=6523dd3ad3c6c2.14907660`;

        // Fetch data using Axios
        am5.net.load(apiUrl).then(function (result) {
          // Parse the CSV data
          const data = am5.CSVParser.parse(result.response, {
            delimiter: ",",
            skipEmpty: true,
            useColumnNames: true,
          });

          // Process and set data for the series
          const processor = am5.DataProcessor.new(root, {
            dateFields: ["Date"],
            dateFormat: "yyyy-MM-dd",
            numericFields: [
              "Open",
              "High",
              "Low",
              "Close",
              "Adjusted_close",
              "Volume",
              "date",
            ],
          });
          processor.processMany(data);

          series.forEach((item) => {
            item.data.setAll(data);
          });
        });
      }

      // Call loadData with the company code and series
      loadData(code, [valueSeries, sbSeries]);

      // Add Indicator Controls to the StockToolbar
      var toolbar = am5stock.StockToolbar.new(root, {
        container: document.getElementById("chartcontrols"),
        stockChart: stockChart,
        controls: [
          am5stock.IndicatorControl.new(root, {
            stockChart: stockChart,
          }),
          am5stock.DateRangeSelector.new(root, {
            stockChart: stockChart,
          }),

          am5stock.ResetControl.new(root, {
            stockChart: stockChart,
          }),
          am5stock.SettingsControl.new(root, {
            stockChart: stockChart,
          }),
          am5stock.PeriodSelector.new(root, {
            stockChart: stockChart,
          }),
        ],
      });

      // Store the root object in the ref for cleanup on unmount
      rootRef.current = root;
    }

    // Clean up the chart when the component unmounts
    return () => {
      if (rootRef.current) {
        rootRef.current.dispose();
      }
    };
  }, [code]);

  useEffect(() => {
    axios
      .get(
        `https://eodhd.com/api/fundamentals/${code}.SR?api_token=6523dd3ad3c6c2.14907660`,
      )
      .then((response) => {
        setCompanyData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  }, [code]);

  return (
    <>
      <DeatialsBanner name={companyData.General?.Name} />
      {/*<h1>*/}
      {/*  Company Details for {companyData.General && companyData.General.Name}*/}
      {/*</h1>*/}
      <div id="chartcontrols" style={{ width: "100%", height: "50px" }}></div>
      <div
        ref={chartDivRef}
        id="chartdiv"
        style={{ width: "100%", height: "500px" }}
      ></div>
      <TabChartDetails />
      <CompanyDetailsTab />
    </>
  );
};

export default CompanyDetails;
