import React from "react";
import "./KeyState.css";
const KeyState = () => {
  return (
    <>
      <div className="state-card">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="key-state">Key Stats</h2>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Open</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Day High</span>
              <span className="key-span">28.07</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Day Low</span>
              <span className="key-span">27.72</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Prev Close</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">10 Day Average Volume</span>
              <span className="key-span">28.00</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">52 Week High</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">52 Week High Date</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">52 Week Low</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">52 Week Low Date</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Beta</span>
              <span className="key-span">28.00</span>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Market Cap</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Shares Out</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Dividend</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">Dividend Yield</span>
              <span className="key-span">28.00</span>
            </div>
            <div className="d-flex align-items-center justify-content-between div-state">
              <span className="key-span">YTD % Change</span>
              <span className="key-span">28.00</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeyState;
