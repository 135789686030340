import React, { useState } from "react";
import styles from "../Companies/CompaniesTab.module.css";
import Stocks from "../Companies/CompaniesTabs/Stocks/Stocks";
import Updates from "../Companies/CompaniesTabs/Updates/Updates";
import Technicals from "../Companies/CompaniesTabs/Technicals/Technicals";
import Financials from "./StocksTab/Financials/Financials";
import TradeUpdates from "./StocksTab/Trade Updates/TradeUpdates";
import AnnouncementsCorporate from "./StocksTab/ Announcements & Corporate Action/AnnouncementsCorporate";

const StockTabDetails = () => {
  const [activeTab, setActiveTab] = useState("financials");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className={`${styles.dailyTabsNav}`}>
              <ul className={`${styles.dailyTabsList}`}>
                <li>
                  <button
                    className={
                      activeTab === "tradeUpdates" ? styles.active : undefined
                    }
                    onClick={() => handleTabClick("tradeUpdates")}
                  >
                    Trade Updates
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "financials" ? styles.active : undefined
                    }
                    onClick={() => handleTabClick("financials")}
                  >
                    Financials
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "announcementsCorporateAction"
                        ? styles.active
                        : undefined
                    }
                    onClick={() =>
                      handleTabClick("announcementsCorporateAction")
                    }
                  >
                    Announcements & Corporate Action
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "updates" ? styles.active : undefined
                    }
                    onClick={() => handleTabClick("updates")}
                  >
                    Updates
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "dividends" ? styles.active : undefined
                    }
                    onClick={() => handleTabClick("dividends")}
                  >
                    Dividends
                  </button>
                </li>
                <li>
                  <button
                    className={
                      activeTab === "shareholding" ? styles.active : undefined
                    }
                    onClick={() => handleTabClick("shareholding")}
                  >
                    Shareholding
                  </button>
                </li>
              </ul>
            </nav>

            <div className={styles.tabContent}>
              {activeTab === "tradeUpdates" && <div>{<TradeUpdates />}</div>}
              {activeTab === "financials" && <div>{<Financials />}</div>}
              {activeTab === "announcementsCorporateAction" && (
                <div>{<AnnouncementsCorporate />}</div>
              )}
              {activeTab === "updates" && <div>{<Updates />}</div>}
              {activeTab === "dividends" && (
                <div>{/* Render Aggregated Deliveries content here */}</div>
              )}
              {activeTab === "shareholding" && (
                <div>{/* Render Aggregated Deliveries content here */}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StockTabDetails;
