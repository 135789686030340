import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./DailyReportsTabs.module.css";

const DailyReportsTabs = () => {
  return (
    <nav className={`${styles.dailyTabsNav}`}>
      <ul className={`${styles.dailyTabsList}`}>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/updates"
          >
            Updates
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/forthcoming"
          >
            Forthcoming
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/indices"
          >
            Indices
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/FLL-DLL"
          >
            FLL/DLL
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/futures-OI"
          >
            Futures OI
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/options-OI"
          >
            Options OI
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/deals"
          >
            Deals
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) =>
              isActive ? styles.active : undefined
            }
            to="/dailyReports/results"
          >
            Results
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default DailyReportsTabs;
