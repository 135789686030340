import React, { useState } from "react";
import styles from "./CompaniesTab.module.css";
import Updates from "./CompaniesTabs/Updates/Updates";
import Technicals from "./CompaniesTabs/Technicals/Technicals";
import Stocks from "./CompaniesTabs/Stocks/Stocks";

const CompanyDetailsTab = () => {
  const [activeTab, setActiveTab] = useState("stocks");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <nav className={`${styles.dailyTabsNav}`}>
        <ul className={`${styles.dailyTabsList}`}>
          {/*<li>*/}
          {/*  <button*/}
          {/*    className={activeTab === "prices" ? styles.active : undefined}*/}
          {/*    onClick={() => handleTabClick("prices")}*/}
          {/*  >*/}
          {/*    Prices*/}
          {/*  </button>*/}
          {/*</li>*/}
          <li>
            <button
              className={activeTab === "stocks" ? styles.active : undefined}
              onClick={() => handleTabClick("stocks")}
            >
              Stocks
            </button>
          </li>
          <li>
            <button
              className={
                activeTab === "stocksTechnicals" ? styles.active : undefined
              }
              onClick={() => handleTabClick("stocksTechnicals")}
            >
              Stocks Technicals
            </button>
          </li>
          <li>
            <button
              className={
                activeTab === "aggregatedDeliveries" ? styles.active : undefined
              }
              onClick={() => handleTabClick("aggregatedDeliveries")}
            >
              Aggregated Deliveries
            </button>
          </li>
          <li>
            <button
              className={activeTab === "updates" ? styles.active : undefined}
              onClick={() => handleTabClick("updates")}
            >
              Updates
            </button>
          </li>
          <li>
            <button
              className={activeTab === "technicals" ? styles.active : undefined}
              onClick={() => handleTabClick("technicals")}
            >
              Technicals
            </button>
          </li>
          <li>
            <button
              className={activeTab === "futuresOI" ? styles.active : undefined}
              onClick={() => handleTabClick("futuresOI")}
            >
              Futures OI
            </button>
          </li>
          <li>
            <button
              className={
                activeTab === "optionChain" ? styles.active : undefined
              }
              onClick={() => handleTabClick("optionChain")}
            >
              Option Chain
            </button>
          </li>
        </ul>
      </nav>

      <div className={styles.tabContent}>
        {/*{activeTab === "prices" && <div>ahmed</div>}*/}
        {activeTab === "stocks" && <div>{<Stocks />}</div>}
        {activeTab === "stocksTechnicals" && (
          <div>{/* Render Stocks Technicals content here */}</div>
        )}
        {activeTab === "aggregatedDeliveries" && (
          <div>{/* Render Aggregated Deliveries content here */}</div>
        )}
        {activeTab === "updates" && <div>{<Updates />}</div>}
        {activeTab === "technicals" && <div>{<Technicals />}</div>}
        {activeTab === "futuresOI" && (
          <div>{/* Render Futures OI content here */}</div>
        )}
        {activeTab === "optionChain" && (
          <div>{/* Render Option Chain content here */}</div>
        )}
      </div>
    </>
  );
};

export default CompanyDetailsTab;
