import React from "react";
import styles from "./Header.module.css";
import userImg from "../../assets/images/userimage.jpg";
const Header = () => {
  return (
    <>
      <header className={`${styles["header-search"]}`}>
        <div className="row justify-content-between align-items-center m-0">
          {/*  <!-- Start Search Input -->*/}
          <div
            className={`${styles.search} d-flex align-items-center px-16 col-4 p-0`}
          >
            <svg
              className={`${styles.faSearch} ml-16`}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#1D1A39"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M21 21L16.65 16.65"
                stroke="#1D1A39"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              type="text"
              className="form-control border-radius-16 pr-16 pl-48"
              placeholder="Search for various stocks"
            />
          </div>
          {/*   <!--End Search-->
                    <!-- Start Notifications and profile-->*/}

          <div className="d-flex align-items-center justify-content-end col-4 p-0 gap-3">
            <div className={`${styles.notificationContainer}`}>
              <div className={`${styles.notification}`}>
                <svg
                  width="30"
                  height="36"
                  viewBox="0 0 30 36"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.7225 28V29.25C19.7225 30.5761 19.2026 31.8479 18.2773 32.7855C17.3519 33.7232 16.0968 34.25 14.7881 34.25C13.4795 34.25 12.2244 33.7232 11.299 32.7855C10.3737 31.8479 9.85381 30.5761 9.85381 29.25V28M28.0245 25.4555C26.0446 23 24.6468 21.75 24.6468 14.9805C24.6468 8.78125 21.5227 6.57266 18.9515 5.5C18.6099 5.35781 18.2884 5.03125 18.1844 4.67578C17.7333 3.12031 16.4689 1.75 14.7881 1.75C13.1074 1.75 11.8422 3.12109 11.3958 4.67734C11.2917 5.03672 10.9702 5.35781 10.6287 5.5C8.05432 6.57422 4.93336 8.775 4.93336 14.9805C4.9295 21.75 3.5317 23 1.55179 25.4555C0.731461 26.4727 1.45002 28 2.88484 28H26.6992C28.1263 28 28.8402 26.468 28.0245 25.4555Z"
                    stroke="#1D1A39"
                    strokeWidth="2.3125"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div className="d-flex align-items-center">
              <div
                className={`${styles.profileDropdown} d-flex align-items-center`}
              >
                <img
                  src={userImg}
                  className={`${styles.userImg}`}
                  alt="user profile"
                />
                {/* dropdown align-items-center */}
                <div className="">
                  <div className="align-items-center d-flex ">
                    {/* dropdown-toggle */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className={`ml-8 mr-8 ${styles.userProfileButton} `}>
                      Khaled Abo Daif
                    </a>

                    <svg
                      width="40"
                      height="40"
                      viewBox="0 0 40 40"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.525391"
                        width="39.4747"
                        height="40"
                        rx="8"
                        fill="#FEFAFA"
                      />
                      <path
                        d="M14.2325 15.2929L20.7733 21.8336L27.314 15.2929C27.7045 14.9024 28.3377 14.9024 28.7282 15.2929C29.1187 15.6834 29.1187 16.3166 28.7282 16.7071L21.4804 23.9549C21.2928 24.1425 21.0385 24.2478 20.7733 24.2478C20.5081 24.2478 20.2537 24.1425 20.0662 23.9549L12.8183 16.7071C12.7695 16.6583 12.7268 16.6057 12.6901 16.5502C12.4339 16.1621 12.4766 15.6346 12.8183 15.2929C13.2088 14.9024 13.842 14.9024 14.2325 15.2929Z"
                        fill="#1D1A39"
                      />
                    </svg>
                    {/*<a className={`ml-16 ${styles.userProfileButton} dropdown-toggle`}*/}
                    {/*   data-bs-toggle="collapse" data-bs-target="#profile-collapse"*/}
                    {/*   aria-expanded="false" href="javascript:void(0);">*/}
                    {/*    Khaled Abo Daif*/}
                    {/*</a>*/}
                    {/*<div className="collapse" id="profile-collapse">*/}
                    {/*    <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">*/}
                    {/*        <li><a className="dropdown-item" href="#">Action</a></li>*/}
                    {/*        <li><a className="dropdown-item" href="#">Another action</a></li>*/}
                    {/*        <li><a className="dropdown-item" href="#">Something else here</a></li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*   <!-- End  Notifications and profile-->*/}
        </div>
      </header>
    </>
  );
};

export default Header;
