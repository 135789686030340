import React from "react";
import "./NotFound.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaWhatsappSquare,
} from "react-icons/fa";
import { Link } from "react-router-dom";
function NotFound() {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
        </div>
        <h2>Oops! Nothing was found</h2>
        <p>
          The page you are looking for might have been removed had its name
          changed or is temporarily unavailable.{" "}
          <Link to={"/"}>Return to Home-Page</Link>
        </p>
        <div className="notfound-social">
          <a href="#">
            <FaFacebookSquare />
          </a>
          <a href="#">
            <FaTwitterSquare />
          </a>
          <a href="#">
            <FaWhatsappSquare />
          </a>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
